import "../scss/section.scss";

const Candidates = () => {
  return (
    <section className="section">
      <h1 className="section__header">Kandydaci</h1>
      <div className="section__text">
        <p>
          <strong>Kandydat na BURMISTRZA SKOCZOWA - MAREK ŻAGAN</strong>
        </p>
      </div>
      <div className="section__text">
        <strong>Kandydaci w okręgu wyborczym nr 1</strong>
        <ol>
          <li>ŻAGAN Marek</li>
          <li>WALCZUK Artur</li>
          <li>SZAFRANIEC Marek</li>
          <li>KĘDZIERSKI Jakub</li>
          <li>GOJDKA-MAŁYSZ Beata </li>
          <li>WISEŁKA Natalia</li>
          <li>KOWALCZYK Justyna</li>
          <li>DUDA Michał </li>
        </ol>
      </div>
      <div className="section__text">
        <strong>Kandydaci w okręgu wyborczym nr 2</strong>
        <ol>
          <li>MITRĘGA Ryszard</li>
          <li>WĄTOREK Joanna</li>
          <li>BABIJ Katarzyna</li>
          <li>SZKUCIK Szymon </li>
          <li>CHROBOK Klaudia </li>
          <li>PNIOK Martyna</li>
          <li>LUPA Kacper</li>
        </ol>
      </div>
      <div className="section__text">
        <strong>Kandydaci w okręgu wyborczym nr 3</strong>
        <ol>
          <li>BIENIEK Katarzyna</li>
          <li>KRUŻOŁEK Jacek </li>
          <li>CHMIEL Zbigniew </li>
          <li>KROCZEK Anna</li>
          <li>MACIANTOWICZ Barbara </li>
          <li>ŁAGAN Daniel</li>
        </ol>
      </div>
      <div className="section__text">
        <strong>Kandydaci w okręgu wyborczym nr 4</strong>
        <ol>
          <li>BIAŁOŃ Fryderyk </li>
          <li>BORECKI Emil</li>
          <li>ZJAWIONY Andrzej</li>
          <li>DEMEL Oliwia Joanna</li>
          <li>BIAŁOŃCZYK Barbara Maria</li>
          <li>SWOBODA Ilona</li>
          <li>RODENKO Mariusz</li>
        </ol>
      </div>
    </section>
  );
};

export default Candidates;

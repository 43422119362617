import '../scss/footer.scss'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__text">KWW Z Sercem dla Gminy Skoczów</div>{" "}
        <div className="footer__text">Copyright &copy; 2024</div>
        <div className="footer__text">
          Realizacja: Agencja Marketingu Internetowego{" "}
          <a href="https://optimalit.pl" target="_blank" rel="noreferrer">
            <img src="./../assets/img/optimalit.png" className="footer__logo" alt="" rel="noreferrer" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { NavLink, useLocation } from "react-router-dom";
import "../scss/colors.scss";
import "../scss/header.scss";
import "../scss/menu.scss";
import { useEffect, useState } from "react";

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
  
    setShowMenu(!showMenu);
  }
  
  useEffect(() => {
    setShowMenu(false)
  },[location.pathname])

  return (
    <div>
      <nav className={`menu ${showMenu ? "menu--show" : ""}`}>
        <div className="menu__container">
          <ul>
            <li>
              <NavLink to="/">Kandydaci</NavLink>
            </li>
            <li>
              <NavLink to="/wplaty"> Wpłaty </NavLink>
            </li>
            <li>
              <a
                href="https://www.facebook.com/zsercemdlagminySkoczow"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <button onClick={toggleMenu} className="hamburger">
        {showMenu ? (
          <i class="fa-solid fa-xmark"></i>
        ) : (
          <i class="fa-solid fa-bars"></i>
        )}
      </button>
    </div>
  );
};

export default Menu;



import '../scss/section.scss';
const Deposits = () => {
  return (
      <section className='section'>
      <h1 className="section__header">
        Wpłaty
      </h1>
      <div class="section__text">
        <p> Brak wpisów w rejestrze.</p>
      </div>
    </section>
  )
}

export default Deposits
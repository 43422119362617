import * as React from "react"

import Footer from './components/Footer';
import Header from './components/Header';
import Menu from './components/Menu';
import './scss/style.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Candidates from "./views/Candidates"
import Deposits from "./views/Deposits";
import "./scss/style.scss";

function App() {
  return (
    <Router>
        <Header />
        <Menu />
        <div className="container">
          <Routes>
            <Route index element={<Candidates />} />
            <Route path="/wplaty" element={<Deposits />} />
          </Routes>
        </div>
        <Footer />
    </Router>
  );
}

export default App;

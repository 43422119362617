
import '../scss/header.scss';

const Header = () => {
  return (
    <header className="header">
          <div className="header__container">
              <a href="/" className="header__logo">
                  <img src="./../assets/img/banner.png" alt=""  />
              </a>
        </div>
    </header>
  )
}

export default Header